import React from "react";
import ReactDOM from "react-dom";
import "src/fonts/style.css";
import App from "./App";
import { GlobalContextProvider } from "./context/GlobalContext";
import { axHistory, HistoryRouter } from "./resources/axHistory";
import ScrollToTop from "./resources/scrollTop";
import "./tailwind.css";

ReactDOM.render(
  <React.StrictMode>
    <HistoryRouter history={axHistory}>
      <GlobalContextProvider>
        <ScrollToTop />
        <App />
      </GlobalContextProvider>
    </HistoryRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
