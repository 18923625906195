import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  notification,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Modal from "antd/lib/modal/Modal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import AmityLogo from "../../assets/images/amity.png";
import LightLogo from "../../assets/images/logo.png";
import SecureImage from "../../assets/images/secure-stripe.png";

const StorePage = () => {
  const { id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [initialRender, setInitialRender] = useState(true);
  const [textbooks, setTextbooks] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [stationery, setStationery] = useState([]);
  const [details, setDetails] = useState(null);
  const [spack, setSpack] = useState(true);
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [visible, setVisible] = useState(false);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [studentName, setStudentName] = useState(null);
  const { orders, setOrders, total, setTotal } = useContext(GlobalContext);
  const [payment, setPayment] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountCode, setDiscountCode] = useState(null);
  const [discountedTotal, setDiscountedTotal] = useState(0);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getYearProducts(id).then((data) => {
        setTextbooks(data.data.textbooks);
        setExercises(data.data.exercises);
        setStationery(data.data.stationery);
        setDetails(data.data.details);
        setPageLoading(false);
      });
    }
  }, [initialRender]);

  const adetChanged = (index, adet) => {
    let temp = [...textbooks];
    temp[index].adet = adet;
    setTextbooks(temp);
  };
  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Colour",
      dataIndex: "colour",
      className: lg ? "kolonon" : "kolonoff",
    },
  ];
  const items = [
    {
      title: "Item Name",
      dataIndex: "name",
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];
  const stationeryChanged = (ve) => {
    setSpack(ve.target.checked);
  };
  const getTotal = () => {
    var total = getTotalWithoutDelivery();
    total = total + deliveryPrice;
    return total;
  };
  const getTotalWithoutDelivery = () => {
    var total = 0;
    textbooks.forEach((tb) => {
      total = total + tb.price * tb.adet;
    });
    total = total + details[0].packprice;
    total = spack ? total + details[1].packprice : total;
    return total;
  };

  const handleSubmit = useCallback(
    async function (values) {
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }
        //   const total = getTotal();
        const tbs = [];
        textbooks.forEach((tb) => {
          if (tb.adet > 0) {
            tbs.push({ id: tb.id, adet: tb.adet, price: tb.price });
          }
        });
        const sta = spack;
        const dlp = deliveryPrice;
        //   const dlo = deliveryOption;
        const ylv = details[0].yearlevelId;

        const newobj = {
          ...values,
          total: total.toFixed(2),
          discountPrice: discountPrice.toFixed(2),
          discountedTotal:
            discountCode === null
              ? total.toFixed(2)
              : discountedTotal.toFixed(2),
          discountCode,
          newOrders: orders,
        };
        let response = await AdminService.finalizeOrder({
          ...newobj,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });

        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrder({
            ...newobj,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your payment!",
          });

          form.resetFields();
          navigate("/submitted", { replace: true });
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      getTotal,
      textbooks,
      spack,
      deliveryPrice,
      deliveryOption,
      details,
      stripe,
      elements,
      form,
      navigate,
      discountCode,
      discountPrice,
      discountedTotal,
    ]
  );

  const onChangeDelivery = (v) => {
    setDeliveryOption(v);
    // if (v === "Address") {
    //   setVisible(true);
    // } else {
    //   setDeliveryPrice(0);
    // }
  };
  const pFormFinish = (/** @type {{ postcode: number; }} */ v) => {
    form.setFieldsValue({ postcode: v.postcode.toString() });
    if (getTotalWithoutDelivery() < 150) {
      if (v.postcode < 2000 || v.postcode > 4999) {
        notification.error({ message: "Invalid Postcode!" });
        return;
      }
      setPostcode(v.postcode);
      if (v.postcode > 1999 || v.postcode < 2235) {
        setDeliveryPrice(12.99);
      } else {
        setDeliveryPrice(19.99);
      }
      if (v.postcode === 2171 || v.postcode === 2173) {
        setDeliveryPrice(19.99);
      }
      if (v.postcode > 2235) {
        setDeliveryPrice(19.99);
      }
    } else {
      setDeliveryPrice(0);
    }

    setVisible(false);
  };

  const checkDiscountCode = () => {
    AdminService.checkDiscountCode(discountCode).then((data) => {
      if (!data.data.success) {
        notification.error({ message: data.data.message });
        return;
      }
      setDiscountPrice(total * 0.1);
      setDiscountedTotal(total - total * 0.1);
      notification.success({
        message: "$" + (total * 0.1).toFixed(2) + " discount applied!",
      });
    });
  };

  return (
    <div>
      {pageLoading && (
        <div className="min-h-screen flex justify-center items-center font-semibold text-lg text-gray-500">
          <Space>
            <LoadingOutlined /> <span>Loading...</span>
          </Space>
        </div>
      )}
      {details && (
        <div className="container  mx-auto p-2 border border-gray-300 lg:p-4  bg-white shadow-lg  mb-16">
          <img src={LightLogo} className="h-16" alt="logo" />
          <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
              if (postcode === null) {
                setDeliveryOption(null);
                setDeliveryPrice(0);
              }
              setVisible(false);
            }}
            closable={false}
            title="Enter your Postcode"
          >
            <Form onFinish={pFormFinish}>
              <Form.Item
                name="postcode"
                rules={[
                  { required: true, message: "Postcode is not correct!" },
                ]}
              >
                <InputNumber
                  min={1999}
                  max={5000}
                  placeholder="Postcode"
                  autoFocus
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <div className="mt-4 lg:mt-0 w-full text-center">
            <img src={AmityLogo} alt="amity logo" className="h-20 mx-auto" />
            <p className="text-xl font-semibold text-sky-500">
              {details[0].yearlevel} Resource List 2025{" "}
            </p>
          </div>
          {step1 && (
            <div>
              <Divider orientation="left">Textbooks </Divider>
              <Row gutter={[16, 16]}>
                {textbooks.map((tb, index) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={8}
                    xxl={8}
                    key={tb.id}
                  >
                    <div className="bg-zinc-50 border border-gray-300 m-1 p-3">
                      <div className="mb-4">
                        <span className="text-base text-sky-500 font-semibold">
                          {tb.subject}
                        </span>
                        <br />
                        <span>{tb.title}</span>
                        <br />
                        <Space>
                          {tb.author && (
                            <>
                              <span>Author: {tb.author}</span>
                              {" / "}
                            </>
                          )}
                          {tb.publisher && (
                            <span>Publisher: {tb.publisher}</span>
                          )}
                        </Space>
                      </div>
                      <Space size="large">
                        {/* <Select
                          defaultValue={textbooks[index].adet}
                          className="w-32 "
                          onChange={(a) => adetChanged(index, a)}
                        >
                          {GlobalVariables.adetler.map((adet) => (
                            <Select.Option key={adet.key} value={adet.value}>
                              {adet.key}
                            </Select.Option>
                          ))}
                        </Select>*/}
                        <span className="text-base font-semibold">
                          ${(textbooks[index].adet * tb.price).toFixed(2)}
                        </span>
                      </Space>
                    </div>
                  </Col>
                ))}
              </Row>
              {details[0].yearlevel !== "Kindergarten" && (
                <p>
                  Languages textbooks are purchased separately from Light
                  Bookstore. ^TSL: Turkish & Turkish as a Second Language (NB:
                  Please ensure you know which Languages class your child is in
                  before purchasing the textbooks)
                </p>
              )}
              <div className="max-w-5xl">
                <br />
                <Divider orientation="left">Exercise Books</Divider>
                <div className="lg:max-w-5xl">
                  <Table
                    footer={() => (
                      <div className="text-right pr-4">
                        <span className="text-base font-semibold ">
                          Exercise Books Pack Price ${details[0].packprice}
                        </span>
                      </div>
                    )}
                    rowKey="id"
                    bordered
                    size="small"
                    dataSource={exercises}
                    columns={columns}
                    pagination={false}
                    className="border border-gray-300"
                  />
                </div>
                <span>
                  NB: The exercise book pack <b>must</b> be purchased from the
                  Light Bookstore as they are custom made books.
                </span>
                <br />
                {details[0].yearlevel !== "Kindergarten" && (
                  <span>
                    * Students studying Arabic must purchase an additional
                    exercise book, not included in the above pack. Please ask
                    the Light Bookstore for a book
                  </span>
                )}
                <br />
                <br />
                <Divider orientation="left">Stationery</Divider>
                <div className="lg:max-w-5xl">
                  <Table
                    footer={() => (
                      <div className="text-right pr-4">
                        <span className="text-base font-semibold ">
                          Stationery Pack Price ${details[1].packprice}
                        </span>
                      </div>
                    )}
                    rowKey="id"
                    bordered
                    size="small"
                    dataSource={stationery}
                    columns={items}
                    pagination={false}
                    className="border border-gray-300 "
                  />
                </div>
                <span>
                  NB: The stationery pack is optional; stationery may be
                  purchased elsewhere.
                </span>
                <br />
                <Checkbox onChange={stationeryChanged} checked={spack}>
                  <span className="text-base ">
                    I want to buy stationery pack
                  </span>
                </Checkbox>
                <br />
              </div>
              <br />
              <Divider orientation="left">Student Details</Divider>
              <Select
                placeholder="Student Campus"
                size="large"
                className="w-full"
                onSelect={onChangeDelivery}
                value={deliveryOption}
              >
                <Select.Option value="Prestons">Prestons</Select.Option>
                <Select.Option value="Auburn">Auburn</Select.Option>
                <Select.Option value="Illawarra">Illawarra</Select.Option>
                <Select.Option value="Leppington">Leppington</Select.Option>
              </Select>
              <br />
              <Input
                value={studentName}
                onChange={(a) => setStudentName(a.target.value)}
                placeholder="Student full name"
                size="large"
                className="!my-2 !w-full"
              />
              <br />
              <br />
              <div className="max-w-sm">
                <Button
                  block
                  onClick={() => {
                    if (studentName === null || deliveryOption === null) {
                      notification.warning({
                        message: "Please select campus and type student name",
                      });
                      return;
                    }

                    // setDeliveryOption(null);
                    // setDeliveryPrice(0);
                    // setStudentName(null);
                    const tmp = {
                      textbooks,
                      spack,
                      exercise: details[0].packprice,
                      stationery: details[1].packprice,
                      studentName,
                      yearLevelId: details[0].yearlevelId,
                      campus: deliveryOption,
                      studentTotal: getTotal(),
                    };

                    console.log(tmp);
                    const tt = orders;
                    tt.push(tmp);
                    setOrders(tt);
                    setTotal(total + getTotal());
                    setStep1(false);
                    setStep3(true);
                  }}
                  type="primary"
                  size="large"
                  icon={<ArrowRightOutlined />}
                >
                  Next
                </Button>
              </div>
              <br />
              <br />
            </div>
          )}
          {step2 && (
            <div className="max-w-5xl">
              <br />
              <p className="text-xl font-semibold text-sky-500 -mb-3">
                Select Student Details
              </p>
              <Divider />

              <Button
                size="large"
                onClick={() => {
                  setStep1(true);
                  setStep2(false);
                }}
                type="primary"
                icon={<ArrowLeftOutlined />}
              >
                Back
              </Button>
              <Button size="large" className="!bg-sky-600 !text-white !ml-2">
                Add Another Set
              </Button>
              <Button
                size="large"
                className="ml-1"
                onClick={() => {
                  if (deliveryOption === null) {
                    notification.error({
                      message: "Please select delivery option!",
                    });
                    return;
                  }
                  setStep2(false);
                  setStep3(true);
                }}
                type="primary"
                icon={<ArrowRightOutlined />}
              >
                Payment
              </Button>
              <br />
            </div>
          )}
          {step3 && (
            <div className="max-w-5xl">
              <br />
              <p className="text-xl font-semibold text-sky-500 -mb-3">
                Your Orders
              </p>
              {orders.map((order, index) => (
                <div key={index}>
                  <Divider />
                  <span className="font-semibold text-lg">
                    Student Name: {order.studentName}
                  </span>
                  <br />
                  <div className="flex justify-between mb-2">
                    <span>Campus: {order.campus}</span>
                    <Button
                      icon={<DeleteOutlined />}
                      type="primary"
                      danger
                      onClick={() => {
                        notification.success({ message: "Order Removed!" });
                        console.log(index);
                        setTotal(
                          total.toFixed(2) -
                            orders[index].studentTotal.toFixed(2)
                        );

                        var ccc = orders.filter((a, ind) => ind !== index);
                        setOrders(ccc);
                      }}
                    />
                  </div>
                  <div className="lg:text-base max-w-5xl space-y-2 bg-zinc-50 border border-gray-300 p-4">
                    {order.textbooks.map(
                      (tb) =>
                        tb.adet > 0 && (
                          <div className="flex justify-between" key={tb.id}>
                            <span>
                              <b>{tb.subject}</b>{" "}
                              <span>
                                / {tb.title} / {tb.adet} qty
                              </span>
                            </span>
                            <span>${(tb.price * tb.adet).toFixed(2)}</span>
                          </div>
                        )
                    )}
                    <div className="flex justify-between">
                      <span>
                        <b>Exercise Books</b> <span>/ Pack Price</span>
                      </span>
                      <span>${order.exercise.toFixed(2)}</span>
                    </div>
                    {order.spack && (
                      <div className="flex justify-between">
                        <span>
                          <b>Stationery</b> <span>/ Pack Price</span>
                        </span>
                        <span>${order.stationery.toFixed(2)}</span>
                      </div>
                    )}
                    {/* <div className="flex justify-between">
                  <span>
                    <b>Delivery</b>{" "}
                    {deliveryOption && (
                      <span>
                        /{" "}
                        {deliveryOption === "Address"
                          ? "Address"
                          : `Pick up from ${deliveryOption}`}
                      </span>
                    )}
                  </span>
                  <span>${deliveryPrice.toFixed(2)}</span>
                </div> */}
                    <Divider />
                    <div className="flex justify-between">
                      <span>
                        <b>Total</b>
                      </span>
                      <span>
                        <b>${order.studentTotal.toFixed(2)}</b>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <br />
              <div className="max-w-sm">
                <Button
                  block
                  onClick={() => {
                    setStep1(true);
                    setStep3(false);
                    var aaa = orders;
                    aaa.splice(-1, 1);
                    setTotal(total - getTotal());
                    setOrders(aaa);
                  }}
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  className="mb-2"
                >
                  Change Last Order
                </Button>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    console.log(getTotal());
                    console.log(textbooks);
                    console.log(spack);
                    console.log(details[0].packprice);
                    console.log(details[1].packprice);
                    console.log(studentName);
                    console.log(deliveryOption);

                    setTextbooks(null);
                    setSpack(null);
                    setDetails(null);
                    setStudentName(null);
                    setDeliveryOption(null);
                    setStep1(true);
                    setStep3(false);
                    navigate("/");
                  }}
                  size="large"
                  className="mb-2"
                  ghost
                >
                  Add Another Order
                </Button>
                <Button
                  block
                  size="large"
                  type="primary"
                  onClick={() => {
                    console.log(orders);
                    console.log(total);
                    setPayment(true);
                  }}
                >
                  Check Out
                </Button>
              </div>
              <br />

              <br />
              {payment && (
                <div className="max-w-full  lg:max-w-5xl bg-zinc-50  p-2 lg:p-6 lg:border lg:border-gray-300 mt-6">
                  <label>Do you have a Discount Code? </label>
                  <br />
                  <Space align="baseline">
                    <Input
                      value={discountCode}
                      onChange={(a) => setDiscountCode(a.target.value)}
                      placeholder="Discount Code"
                      className="!mb-2 "
                    />
                    <Button onClick={checkDiscountCode} type="primary">
                      Apply
                    </Button>
                  </Space>
                  <br />
                  <br />

                  <div
                    style={{
                      maxWidth: "100%",
                      overflow: "hidden",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="flex max-w-lg border-b border-gray-100 justify-between">
                      <span>
                        <b>Total ({orders?.length} order)</b>
                      </span>
                      <span>
                        <b>${total.toFixed(2)}</b>
                      </span>
                    </div>
                    <div className="flex max-w-lg border-b border-gray-100 justify-between">
                      <span>
                        <b>Discount</b>
                      </span>
                      <span>
                        <b>${discountPrice.toFixed(2)}</b>
                      </span>
                    </div>
                    <br />
                    <p className="text-lg text-sky-500 font-semibold">
                      {` Billing ${
                        deliveryOption === "Address" ? "& Delivery" : ""
                      } Details`}
                    </p>
                    <Form
                      onFinish={handleSubmit}
                      form={form}
                      style={{ maxWidth: "700px" }}
                      initialValues={{
                        country: "Australia",
                        state: "NSW",
                        subs: true,
                      }}
                    >
                      {/* <Form.Item
                        name="student"
                        rules={[
                          {
                            required: true,
                            message: "Student name is required",
                          },
                        ]}
                      >
                        <Input placeholder="Student Fullname " size="large" />
                      </Form.Item>
                      <Form.Item
                        name="dlo"
                        rules={[
                          { required: true, message: "Campus is required" },
                        ]}
                      >
                        <Select
                          placeholder="Select Student Campus"
                          size="large"
                        >
                          {["Prestons", "Illawarra", "Auburn"].map((campus) => (
                            <Select.Option key={campus} value={campus}>
                              {campus}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}
                      <Form.Item
                        name="fullname"
                        rules={[
                          { required: true, message: "Fullname is required" },
                        ]}
                      >
                        <Input placeholder="Billing Fullname" size="large" />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        style={
                          lg && {
                            display: "inline-block",
                            width: "calc(50% - 8px)",
                          }
                        }
                        rules={[
                          { required: true, message: "Phone is required" },
                        ]}
                      >
                        <Input
                          size="large"
                          // international
                          // defaultCountry="AU"
                          // value={phone}
                          // onChange={(a) => setPhone(a)}
                          placeholder="Phone"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Invalid email address" },
                        ]}
                        style={
                          lg && {
                            display: "inline-block",
                            width: "calc(50% - 0px)",
                            margin: "0 0 0 8px",
                          }
                        }
                      >
                        <Input placeholder="Please Type Email" size="large" />
                      </Form.Item>
                      {deliveryOption === "Address" && (
                        <>
                          <Form.Item name="address">
                            <Input
                              placeholder="Please Type Address"
                              size="large"
                            />
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                              name="suburb"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "Address is required",
                                },
                              ]}
                            >
                              <Input placeholder="Suburb" size="large" />
                            </Form.Item>
                            <Form.Item
                              name="postcode"
                              rules={[
                                {
                                  required: true,
                                  message: "Postcode is required",
                                  type: "string",
                                },
                              ]}
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 0px)",
                                margin: "0 0 0 8px",
                              }}
                            >
                              <Input
                                size="large"
                                placeholder="Postcode"
                                disabled={deliveryOption === "Address"}
                              />
                            </Form.Item>
                          </Form.Item>
                          <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                              name="country"
                              rules={[
                                {
                                  required: true,
                                  message: "Country is required",
                                },
                              ]}
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 8px)",
                              }}
                            >
                              <Input
                                allowClear
                                placeholder="Type Country"
                                size="large"
                              />
                            </Form.Item>
                            <Form.Item
                              name="state"
                              rules={[
                                {
                                  required: true,
                                  message: "State is required",
                                },
                              ]}
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 0px)",
                                margin: "0 0 0 8px",
                              }}
                            >
                              <Input
                                allowClear
                                placeholder="Type State"
                                size="large"
                              />
                            </Form.Item>
                          </Form.Item>
                        </>
                      )}
                      <h4 className="text-base">
                        Please Type Your Credit/Debit Card Details
                      </h4>
                      <CardElement
                        options={{
                          disableLink: true,
                          style: {
                            base: {
                              fontSize: "18px",
                              fontFamily: "monospace",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "red",
                            },
                          },
                        }}
                        className="st-form"
                      />
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        disabled={!stripe || !elements}
                        block
                        style={{ maxWidth: "500px" }}
                        size="large"
                      >
                        <span className="text-lg">
                          Pay Now $
                          {discountPrice === 0
                            ? total.toFixed(2)
                            : discountedTotal.toFixed(2)}
                        </span>
                      </Button>
                      <img
                        src={SecureImage}
                        alt="secure"
                        className="max-w-lg"
                      />
                    </Form>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StorePage;
